@import "fonts.css";

.base-portal{
  background: #404041;
  font-family : "Futura-Book";
  font-size : 30px;
  color : #FFFFFF;
  min-height: 100vh;
}

.img-responsive{
  max-width: 80%;
}

.link{
  @media screen and (max-width: 767px){
    .button-portal{
      margin: {
        top: 10px;
        left: auto;
        right: auto;
      };
      display: block;
    }
  }
}

.button-portal{
  display: inline-block;
  background: none;
  border: 1px solid #909293;
  font-family : "Roboto Condensed";
  font-size : 16px;
  line-height : 37.6px;
  color : #909293;
  border-radius: 10px;
  padding: 2px 15px;
  margin-top: -5px;
  cursor: pointer;
  width: 90px;
  &:hover{
    text-decoration: none;
    color: #93D500;
    border-color: #93D500;
  }
  i{
    display: inline-block;
    position: relative;
    top: 3px;
  }
}

.divisor {
  border: 1px solid rgba(#93D500, .3);
  margin: 60px 0;
}

.mt-100{
  margin-top: 80px;
}

.mt-50{
  margin-top: 50px;
}

.mb-100{
  margin-bottom: 50px;
}